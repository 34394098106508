import { PropsWithChildren } from 'react';
import { User } from '@/types';
import Footer from '@/Partials/Footer';
import Header from "@/Partials/Header";

export default function AppLayout({ user, children }: PropsWithChildren<{ user?: User }>) {
    return (
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
            <Header user={user} />
            <main>{children}</main>
            <Footer />
        </div>
    );
}
